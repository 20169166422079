/*
 * This is the initial API interface
 * we set the base URL for the API
 */

import axios from 'axios';
import store from '@/store';

export const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL + '/api',
  headers: {
    'X-Requested-With': 'XMLHttpRequest'
  },
  withCredentials: true, // required to handle the CSRF token
  withXSRFToken: true
});

/*
 * Add a response interceptor
 */
apiClient.interceptors.response.use(
  response => {
    return response;
  },
  function (error) {
    if (
      error.response &&
      [401, 419].includes(error.response.status) &&
      store.getters['auth/authUser'] &&
      !store.getters['auth/guest']
    ) {
      store.dispatch('auth/logout');
    }
    return Promise.reject(error);
  }
);

export const apiHelper = {
  /**
   * Get the query string from the object
   * @param {Object} object
   * @returns query string
   */
  getQueryStringFromObject(object) {
    if (!object) return '';
    return (
      '?' +
      Object.keys(object)
        .map(key => key + '=' + object[key])
        .join('&')
    );
  }
};
