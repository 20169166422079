import Vue from 'vue';
import store from '@/store/index';
import VueRouter from 'vue-router';
import auth from '@/middleware/auth';
import permission from '@/middleware/permission';
import guest from '@/middleware/guest';
import has2fa from '@/middleware/has2fa'; // Needs to be added last
import middlewarePipeline from '@/router/middlewarePipeline';

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'login',
    meta: { middleware: [guest] },
    component: () => import(/* webpackChunkName: "login" */ '../views/Login')
  },
  {
    path: '/challange',
    name: 'challange',
    meta: { middleware: [guest] },
    component: () => import(/* webpackChunkName: "twofactorchallange" */ '../views/TwoFactorChallange')
  },
  {
    path: '/forgot-password',
    name: 'forgotPassword',
    meta: { middleware: [guest] },
    component: () => import(/* webpackChunkName: "forgot-password" */ '../views/ForgotPassword')
  },
  {
    path: '/reset-password',
    name: 'resetPassword',
    meta: { middleware: [guest] },
    component: () => import(/* webpackChunkName: "forgot-password" */ '../views/ResetPassword')
  },
  {
    name: 'main',
    meta: { middleware: [auth] },
    path: '/',
    component: () => import(/* webpackChunkName: "sems" */ '../views/MainMenu'),
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        meta: { middleware: [auth, has2fa] },
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard')
      },
      {
        path: 'groups/:id',
        props: true,
        meta: { middleware: [auth, permission, has2fa], permission: 'view groups' },
        component: () => import(/* webpackChunkName: "group" */ '../views/Group'),
        children: [
          {
            path: '',
            name: 'group',
            meta: { middleware: [auth, has2fa] },
            component: () => import(/* webpackChunkName: "groupdetailform" */ '../views/GroupDetailForm')
          },
          {
            path: 'subgroups',
            meta: { middleware: [auth, has2fa] },
            component: () => import(/* webpackChunkName: "groupsubgrouplist" */ '../views/GroupSubGroupList')
          },
          {
            path: 'flights',
            meta: { middleware: [auth, has2fa] },
            component: () => import(/* webpackChunkName: "groupflightslist" */ '../views/GroupFlightList')
          }
        ]
      },
      {
        path: '/groups',
        name: 'groups',
        meta: { middleware: [auth, permission, has2fa], permission: 'view groups' },
        component: () => import(/* webpackChunkName: "groups" */ '../views/Groups')
      },
      {
        path: 'subgroups/:id',
        props: true,
        meta: { middleware: [auth, permission, has2fa], permission: 'view groups' },
        component: () => import(/* webpackChunkName: "subgroup" */ '../views/Subgroup'),
        children: [
          {
            path: '',
            name: 'Subgroup',
            meta: { middleware: [auth, has2fa] },
            component: () => import(/* webpackChunkName: "subgroupdetailform" */ '../views/SubgroupDetailForm')
          },
          {
            path: 'hosts',
            meta: { middleware: [auth, has2fa] },
            component: () => import(/* webpackChunkName: "subgrouphostlist" */ '../views/SubgroupHostList')
          },
          {
            path: 'paperwork',
            meta: { middleware: [auth, has2fa] },
            component: () => import(/* webpackChunkName: "subgrouppaperworklist" */ '../views/SubgroupPaperworkList')
          },
          {
            path: 'itinerary',
            meta: { middleware: [auth, has2fa] },
            component: () =>
              import(/* webpackChunkName: "subgroupitineraryitemlist" */ '../views/SubgroupItineraryItemList')
          },
          {
            path: 'namelist',
            meta: { middleware: [auth, has2fa] },
            component: () => import(/* webpackChunkName: "subgroupnamelist" */ '../views/SubgroupNameList')
          },
          {
            path: 'flights',
            meta: { middleware: [auth, has2fa] },
            component: () => import(/* webpackChunkName: "subgroupflightlist" */ '../views/SubgroupFlightList')
          }
        ]
      },
      {
        path: 'organisations/:id',
        meta: { middleware: [auth, permission, has2fa], permission: 'view organisations' },
        props: true,
        component: () => import(/* webpackChunkName: "organisation" */ '../views/Organisation'),
        children: [
          {
            path: '',
            name: 'organisation',
            meta: { middleware: [auth, has2fa] },
            component: () => import(/* webpackChunkName: "subgroupdetailform" */ '../views/OrganisationDetailForm')
          },
          {
            path: 'family-members',
            meta: { middleware: [auth, has2fa] },
            component: () => import(/* webpackChunkName: "subgrouphostlist" */ '../views/OrganisationFamilyMemberList')
          },
          {
            path: 'subgroups',
            meta: { middleware: [auth, has2fa] },
            component: () => import(/* webpackChunkName: "subgrouphostlist" */ '../views/OrganisationSubGroupList')
          },
          {
            path: 'contacts',
            meta: { middleware: [auth, has2fa] },
            component: () => import(/* webpackChunkName: "subgrouphostlist" */ '../views/OrganisationContactList')
          }
        ]
      },
      {
        path: '/organisations',
        name: 'organisations',
        meta: { middleware: [auth, permission, has2fa], permission: 'view organisations' },
        component: () => import(/* webpackChunkName: "organisations" */ '../views/Organisations')
      },
      {
        path: 'people/:id',
        name: 'person',
        meta: { middleware: [auth, permission, has2fa], permission: 'view people' },
        component: () => import(/* webpackChunkName: "person" */ '../views/Person')
      },
      {
        path: '/people',
        name: 'people',
        meta: { middleware: [auth, permission, has2fa], permission: 'view people' },
        component: () => import(/* webpackChunkName: "people" */ '../views/People')
      },
      {
        path: 'family-members/:id',
        props: true,
        meta: { middleware: [auth, permission, has2fa], permission: 'view organisations' },
        component: () => import(/* webpackChunkName: "familymember" */ '../views/FamilyMember'),
        children: [
          {
            path: '',
            name: 'family-member',
            meta: { middleware: [auth, has2fa] },
            component: () => import(/* webpackChunkName: "familymemberdetailform" */ '../views/FamilyMemberDetailForm')
          }
        ]
      },
      {
        path: '/family-members',
        name: 'family-members',
        meta: { middleware: [auth, permission, has2fa], permission: 'view organisations' },
        component: () => import(/* webpackChunkName: "familymembers" */ '../views/FamilyMembers')
      },
      {
        path: 'staff-members/:id',
        name: 'staff-member',
        meta: { middleware: [auth, permission, has2fa], permission: 'view organisations' },
        component: () => import(/* webpackChunkName: "staffmember" */ '../views/StaffMember')
      },
      {
        path: '/staff-members',
        name: 'staff-members',
        meta: { middleware: [auth, permission, has2fa], permission: 'view organisations' },
        component: () => import(/* webpackChunkName: "staffmembers" */ '../views/StaffMembers')
      },
      {
        path: 'users/:id',
        name: 'user',
        meta: { middleware: [auth, permission, has2fa], permission: 'view users' },
        component: () => import(/* webpackChunkName: "user" */ '../views/User')
      },
      {
        path: '/users',
        name: 'users',
        meta: { middleware: [auth, permission, has2fa], permission: 'view users' },
        component: () => import(/* webpackChunkName: "users" */ '../views/Users')
      },
      {
        path: 'usergroups/:id',
        name: 'usergroup',
        meta: { middleware: [auth, permission, has2fa], permission: 'view user groups' },
        component: () => import(/* webpackChunkName: "usergroup" */ '../views/UserGroup')
      },
      {
        path: '/two-factor-authentication',
        name: 'twofactorauthentication',
        meta: { middleware: [auth] },
        component: () => import(/* webpackChunkName: "twofactorauthentication" */ '../views/TwoFactorAuthentication')
      },
      {
        path: '/change-password',
        name: 'changepassword',
        meta: { middleware: [auth] },
        component: () => import(/* webpackChunkName: "changepassword" */ '../views/ChangePassword')
      },
      {
        path: 'namelists',
        name: 'namelists',
        meta: { middleware: [auth, permission, has2fa], permission: 'view namelists' },
        component: () => import(/* webpackChunkName: "namelists" */ '../views/Namelist')
      },
      {
        path: 'usergroups',
        name: 'usergroups',
        meta: { middleware: [auth, permission, has2fa], permission: 'view user groups' },
        component: () => import(/* webpackChunkName: "usergroups" */ '../views/UserGroups')
      },
      {
        path: 'settings',
        name: 'settings',
        meta: { middleware: [auth, permission, has2fa], permission: 'view settings' },
        component: () => import(/* webpackChunkName: "settings" */ '../views/Settings')
      },
      {
        path: 'settings/:id',
        name: 'setting-list',
        meta: { middleware: [auth, permission, has2fa], permission: 'view settings' },
        component: () => import(/* webpackChunkName: "settinglist" */ '../views/SettingList')
      },
      {
        path: 'setting-paperwork-set-list',
        name: 'setting-paperwork-set-list',
        meta: { middleware: [auth, permission, has2fa], permission: 'view settings' },
        component: () => import(/* webpackChunkName: "settinglist" */ '../views/SettingsPaperworkSetList')
      },
      {
        path: '/reports',
        name: 'reports',
        meta: { middleware: [auth, permission, has2fa], permission: 'view reports' },
        component: () => import(/* webpackChunkName: "reports" */ '../views/Reports')
      }
    ]
  },
  {
    path: '/:catchAll(.*)',
    name: 'notFound',
    component: () => import(/* webpackChunkName: "not-found" */ '../views/NotFound')
  }
];

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
});

router.beforeEach((to, from, next) => {
  const middleware = to.meta.middleware;
  const context = { to, from, next, store };
  if (!middleware) {
    return next();
  }

  middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1)
  });
});

export default router;
